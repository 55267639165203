<template>
  <div class="home">
    <router-link to="/portfolio"
      ><img src="/img/DSC_0035.jpg" class="d-block w-100" alt="imagenPrincipal"
    /></router-link>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style scoped></style>
