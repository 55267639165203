<template>
  <div class="d-flex flex-column h-100">
    <main class="flex-shrink-0">
      <!-- Navigation-->
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container px-5">
          <router-link class="navbar-brand" to="/"
            >Facundo Rodriguez</router-link
          >
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link" to="/">Inicio</router-link>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Portfolio
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link class="dropdown-item" to="/portfolio"
                    >General</router-link
                  >
                  <router-link class="dropdown-item" to="/flores"
                    >Flores</router-link
                  >
                </div>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contacto"
                  >Contacto</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <router-view />
    </main>

    <!-- Footer-->
    <footer class="bg-dark py-4 mt-auto">
      <div class="container px-5">
        <div
          class="
            row
            align-items-center
            justify-content-between
            flex-column flex-sm-row
          "
        >
          <div class="col-auto">
            <div class="small m-0 text-white">
              Copyright &copy; Facundo Rodriguez 2021
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  setup() {},
  methods: {},
};
</script>

<style></style>
